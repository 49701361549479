import { reactive } from 'vue'
import common from '@/common/common.js'
import pinia from '@/store/store.js'
import { useSettingStore } from '@/store/setting.js'
const store = useSettingStore(pinia);
export function FFmixin() {

    const navData = reactive({ navContent: {} });

    const setTitle = (navkv, navidsub, navkvsub, callback) => {
        getNavList(navkv, navidsub, navkvsub, '', callback);

    };

    const setTitleByAlias = (navkv, alias, callback) => {
        getNavList(navkv, 0, 0, alias, callback);
    };

    const getNavList = (navkv, navidsub, navkvsub, alias, callback) => {
        let navList = store.navList;
        if (!common.basic.isArrayExist(navList)) {
            common.fetch({ 'act': 'telfort_nav', msg: '' }, res => {
                common.each(res.infodata, item => {
                    item.x_children = common.basic.FFfilter(res.infodata2, 'pid', item.id);
                });
                navList = res.infodata;
                store.setNavList(navList);
                setTitleFun(navList, navkv, navidsub, navkvsub, alias, callback);
            });
        }
        else {
            setTitleFun(navList, navkv, navidsub, navkvsub, alias, callback);
        }
    };


    const setTitleFun = (navList, navkv, navidsub, navkvsub, alias, callback) => {

        let navCur = common.basic.FFfind(navList, 'kv', navkv);

        let seotitle = '';
        let navsub = { 'id': 0, 'kv': 0, 'alias': '' };
        if (common.basic.isObjExist(navCur)) {
            seotitle = common.langInfo(navCur.title, navCur.title_en);
            if (common.basic.isArrayExist(navCur.x_children)) {
                navsub = navidsub == 0 ? navkvsub == 0 ? alias ? common.basic.FFfind(navCur.x_children, 'alias', alias) : navCur.x_children[0] : common.basic.FFfind(navCur.x_children, 'kv', navkvsub) : common.basic.FFfind(navCur.x_children, 'id', navidsub);
                if (common.basic.isObjExist(navsub)) {
                    seotitle = common.langInfo(navsub.title, navsub.title_en);
                }
            }
        }
        else {
            navkv = 0;
        }

        document.title = (seotitle ? seotitle + '-' : '') + store.config.title;
        store.SET_NAVKV(navkv, navsub.id);

        if (callback != undefined) {
            callback(navsub);
        }
    };

    const getNavData = () => {
        if (store.navKv) {
            let navCur = common.basic.FFfind(store.navList, 'kv', store.navKv);
            let id = navCur.id || 0;
            if (common.basic.isObjExist(navCur) && store.navIdSub) {
                let navSub = common.basic.FFfind(navCur.x_children, 'id', store.navIdSub);
                if (common.basic.isObjExist(navSub)) {
                    id = navSub.id;
                }
            }
            if (id > 0) {
                common.fetch({ 'act': 'telfort_navdata', 'exts': 'id=' + id, msg: '' }, res => {
                    navData.navContent = res.infodata;
                });
            }
        }
    };

    return { setTitle, setTitleByAlias, getNavData, navData }
}
